import React, { forwardRef } from "react"


export const Image = ({ img, img2,alt='', styles='' }) => {
  return <img src={img} srcSet={`${img} 1x, ${img2} 2x`}  alt={alt} className={styles}/>
}

export const ImageRef = forwardRef(({ img, img2, alt, className='' }, ref) => {
  return <img ref={ref} src={img} srcSet={`${img} 1x, ${img2} 2x`}  alt={alt} className={className}/>
});
